import ShowForPermission from "helpers/showForPermission";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import Locale from "translations";
export default function Lab({ lab, changeStatusLab, togglePassword }) {
	// console.log({ lab });
	const { Labs } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";

	const history = useHistory();
	return (
		<>
			<tr>
				<td>{lab.name}</td>
				<td>{lab.country[locale]}</td>
				<td>{lab.city[locale]}</td>
				<td>{lab.phone_number}</td>
				{/* <td>{lab.email}</td> */}
				<td>{lab.email}</td>
				<td>
					{lab.status === "active" ? (
						<i className="far fa-check-circle fa-2x text-success fa-fw"></i>
					) : (
						<i className="far fa-times-circle fa-2x text-danger fa-fw"></i>
					)}
				</td>
				<td>
					<ShowForPermission permission="company:edit-lab">
						<Link
							to={`/companies/lab-management/${lab.id}`}
							className="font14 btn-default border-0 font-weight-bold text-gray bg-transparent"
						>
							<i className="far fa-edit fa-fw"></i>
							{Labs.edit}
						</Link>
					</ShowForPermission>
				</td>
				<td>
					{/* <button
						type="button"
						className="btn btn-link text-gray"
						className="font14 btn-default border-0 font-weight-bold text-gray bg-transparent"
						onClick={() => history.push("/companies/branches")}
					>
						<i className="fas fa-code-branch fa-fw text-primary"></i> Branches
					</button> */}
				</td>
				<td>
					<ShowForPermission permission="company:edit-lab">
						<button
							type="button"
							onClick={() => {
								togglePassword(lab.id);
							}}
							className="text-gray btn btn-link "
						>
							<i className="fas fa-unlock-alt"></i> {Labs.changePassword}
						</button>
					</ShowForPermission>
				</td>

				<td>
					{lab.status == "active" ? (
						<ShowForPermission permission="company:deactivate-lab">
							<button
								onClick={() => changeStatusLab(lab.id, "deactivate")}
								type="button"
								className="font14 btn-default border-0 font-weight-bold bg-transparent text-danger"
							>
								<i className="fas fa-stop fa-fw"></i> {Labs.deactivate}
							</button>
						</ShowForPermission>
					) : (
						<ShowForPermission permission="company:activate-lab">
							<button
								onClick={() => changeStatusLab(lab.id, "activate")}
								type="button"
								className="font14 btn-default border-0 font-weight-bold bg-transparent text-success"
							>
								<i className="fas fa-play"></i> {Labs.activate}
							</button>
						</ShowForPermission>
					)}
				</td>

				{/* remove button remove */}
				{/*
				<td>
					<button type="button" className="btn btn-danger">
						{Labs.remove}
					</button>
				</td>
			*/}
			</tr>
		</>
	);
}
