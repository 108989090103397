import React from "react";
import Locale from "translations";
/**
 * @Component TextField
 * @Description This component for any text input group like text, email, etc.
 * @Props
 *    -> type: Type for input field
 *    -> name: Name for input field
 *    -> label: Text label for input field
 *    -> placeholder: Input field placeholder
 *    -> className: HTML classes for input field
 *    -> id: HTML ID
 *    -> color: Bootstrap color
 *    -> value: Input value
 *    -> extraText: Text for prepend input
 *    -> extraTextPostion: prepend || append
 *    -> feedbackMessage: Feedback message for validation
 *    -> onBlur: Input Function
 *    -> onChange: Input Function
 *    -> isInvalid: [Bool] set field is valid or not
 */

const TextField = (props) => {
	const { commons } = Locale;
	const prependText = (
		<div
			className={`control-field__prepend ${
				props.color ? " control-field__prepend--" + props.color : ""
			}`}
		>
			<span className="form-control border-0 text-headline text-center pt-2">
				{props.extraText}
			</span>
		</div>
	);

	const appendText = (
		<div
			className={`control-field__append ${
				props.color ? " control-field__append--" + props.color : ""
			}`}
		>
			<span className="form-control border-0 text-headline text-center pt-2">
				{props.extraText}
			</span>
		</div>
	);

	const generateErrorsMsgs = (errors) => {
		let message = [];
		if (errors) {
			for (const [k, v] of Object.entries(errors)) {
				if (k === "required" && v) {
					message.push(commons.isRequired);
				} else if (k === "min" && v) {
					//message.push(` should be at least ${props.min} chars`);atLeast
					message.push(`${commons.atLeast} ${props.min}  ${commons.char}`);
				} else if (k === "maxNumber" && v) {
					message.push(` should not exceed ${props.max}`);
				} else if (k === "minNumber" && v) {
					message.push(` ${commons.atLeast} ${props.min}`);
				} else if (k === "email" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "phone" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "number" && v) {
					message.push(commons.shouldBeNumber);
				}
			}
			if (message.length === 1) {
				return `${props.label} ${message[0]}`;
			} else if (message.length > 1) {
				return `${props.label} ${message.join(" & ")}`;
			} else {
				return;
			}
		}
	};

	return (
		<>
			<div
				className={`my-2 input-group control-field + ${
					props.color ? " control-field--" + props.color : ""
				}`}
			>
				{/* Text Field Prepend */}
				{props.extraTextPosition === "prepend" ? prependText : ""}

				{/* Text Field Body */}
				<div
					className={`control-field__body ${
						props.disabled ? "isDisabled" : ""
					}`}
				>
					{/* Label */}
					{props.hasLabel ? (
						<label
							className={`control-field__label ${
								props.color ? " control-field__label--" + props.color : ""
							}`}
						>
							{props.label}
						</label>
					) : null}

					{/* Text Input */}
					<div className="row no-gutters justify-content-between">
						<input
							type={props.type}
							name={props.name}
							className={`${
								props.className ?? ""
							} control-field__input w-100 text-caption`}
							id={props.id}
							value={props.value}
							min={props.min}
							max={props.max}
							disabled={props.disabled}
							onChange={props.onChange}
							onKeyUp={props.onKeyUp}
							onBlur={props.onBlur}
							placeholder={props.placeholder}
							autoComplete="off"
							onKeyDown={props.onEnter}
							{...props}
						/>
					</div>
				</div>

				{/* Text Field Append */}
				{props.extraTextPosition === "append" ? appendText : ""}
			</div>

			{/* Error Message */}
			<small
				className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
			>
				{generateErrorsMsgs(props.errors)}
			</small>
		</>
	);
};

TextField.defaultProps = {
	hasLabel: true,
};

export default TextField;
