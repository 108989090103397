import React, { useState } from "react";
import Locale from "translations";
import Counts from "./Counts";
import Filter from "./Filter";
import List from "./List";
export default function Users() {
	const { users } = Locale;

	//Add or Edit User
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	return (
		<>
			<section className=" py-3 medical-section Users">
				<div className="container-fluid">
					<div className="row">
						<Counts />
					</div>

					<div className="medical-result">
						<section className="reservation-section">
							<div className="reservation-box m-0">
								<div className="TeamManagement-header">
									<div className="TeamManagement-title">
										{users.users} ({users.labName})
									</div>
								</div>
								<Filter modal={modal} toggle={toggle} />

								<List toggle={toggle} />
							</div>
						</section>
					</div>
				</div>
			</section>
		</>
	);
}
