import React, { useState } from "react";
import Locale from "translations";
import BranchCounts from "./BranchCounts";
import Filter from "./Filter";
import List from "./List";
export default function Branches() {
	const { branches } = Locale;
	const [active, setActive] = useState(1);

	return (
		<>
			<section className=" py-3 medical-section Users">
				<div className="container-fluid">
					<div className="row">
						<BranchCounts />
					</div>

					<div className="medical-result">
						<section className="reservation-section">
							<div className="reservation-box m-0">
								<div className="TeamManagement-header">
									<div className="TeamManagement-title">
										{branches.branches}
									</div>
								</div>
								<Filter />

								<List />
							</div>
						</section>
					</div>
				</div>
			</section>
		</>
	);
}
