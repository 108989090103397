import axios from "axios";
import React, { useEffect, useState } from "react";
import Locale from "translations";
import User from "./User";

export default function List({ toggle }) {
	const { Labs, teamManagement, users } = Locale;
	const [user, setUser] = useState([]);

	useEffect(() => {
		axios.get("../travelersLabsAdmin.json").then((res) => {
			setUser(res.data.Travelers);
		});
	}, []);

	const AllUsers = user.map((user, index) => (
		<User
			user={user}
			index={index}
			toggle={toggle}
			// removeItem={removeItem}
		/>
	));

	return (
		<>
			<div className="tab-content" id="pills-tabContent">
				<div
					className="tab-pane show active"
					id="pills-home"
					role="tabpanel"
					aria-labelledby="pills-home-tab"
				>
					<div className="reservation-table">
						<table className="table table-striped mb-0">
							<thead>
								<tr className="bg-grey text-white">
									<th className="w-20" scope="col">
										{users.userName}
									</th>
									<th className="w-20" scope="col">
										{users.email}
									</th>
									<th className="w-20" scope="col">
										{users.labName}
									</th>
									<th className="w-10" scope="col">
										{users.status}
									</th>
									<th colSpan="4" scope="col" className="text-center">
										{Labs.processes}
									</th>
								</tr>
							</thead>
							<tbody>
								{AllUsers.length > 0 ? (
									AllUsers
								) : "loading" || true ? null : (
									<tr>
										<td colSpan="11">
											<div className="product-build__product-no-data">
												<i className="fas fa-info-circle fa-lg"></i>{" "}
												<h4>{Labs.noResult}</h4>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
