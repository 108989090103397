import axios from "axios";
import React, { useEffect, useState } from "react";
import Locale from "translations";
import Branch from "./Branch";

export default function List() {
	const { Labs, teamManagement, branches } = Locale;
	const [branch, setBranch] = useState([]);

	useEffect(() => {
		axios.get("../travelers.json").then((res) => {
			setBranch(res.data.Travelers);
		});
	}, []);

	const AllBranches = branch.map((branch, index) => (
		<Branch
			branch={branch}
			index={index}
			// removeItem={removeItem}
		/>
	));

	return (
		<>
			<div className="tab-content" id="pills-tabContent">
				<div
					className="tab-pane show active"
					id="pills-home"
					role="tabpanel"
					aria-labelledby="pills-home-tab"
				>
					<div className="reservation-table">
						<table className="table table-striped mb-0">
							<thead>
								<tr className="bg-grey text-white">
									<th scope="col">{branches.branch}</th>
									<th scope="col">{Labs.governorate}</th>
									<th scope="col">{Labs.email}</th>
									<th scope="col">{branches.mobileNumber}</th>
									<th scope="col">{Labs.status}</th>
								</tr>
							</thead>
							<tbody>
								{AllBranches.length > 0 ? (
									AllBranches
								) : "loading" || true ? null : (
									<tr>
										<td colSpan="11">
											<div className="product-build__product-no-data">
												<i className="fas fa-info-circle fa-lg"></i>{" "}
												<h4>{Labs.noResult}</h4>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
