import TextField from "components/shared/TextField";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Locale from "translations";
export default function ChangePassword({ modalPassword, togglePassword }) {
	const { teamManagement, users } = Locale;

	return (
		<>
			<Modal
				className={`add-model`}
				isOpen={modalPassword}
				toggle={togglePassword}
			>
				<div className="col-md-12 box-title-model">
					<p className="text-center title-model">{users.changePassword}</p>
				</div>

				<ModalBody>
					<div className="col-md-12">
						<div className="col-md-12">
							<div className="row">
								<TextField
									type="password"
									label={users.newPassword}
									placeholder={users.newPasswordPlaceholder}
									// value={addInvitationState.companyName}
								/>
							</div>
							<div className="row">
								<TextField
									type="password"
									label={users.confirmPassword}
									placeholder={users.confirmPasswordPlaceholder}
									// value={addInvitationState.companyName}
								/>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-cancel" onClick={togglePassword}>
						{teamManagement.cancel}
					</Button>
					<Button className="btn btn-add" onClick={togglePassword}>
						{users.changePassword}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
