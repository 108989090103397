import logoImg from "assets/images/login/mainLogo.png";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import PasswordField from "components/shared/PasswordField";
import UserSettings from "components/UserSetting";
import { useLabsDispatch } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import validate from "helpers/validate";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getCurrentUser } from "services/lab";
import Locale from "translations";

function Header() {
	const { pathname } = useLocation();
	const { Labs, teamManagement, branches } = Locale;
	const userType = localStorage.getItem("userType");
	const [userData, setUserData] = useState({});
	const isAuth = localStorage.getItem("isAuth");
	const dispatch = useLabsDispatch();
	const [changePassword, setChangePassword] = useState({});
	const [userid, setUserId] = useState(0);
	const [modalPassword, setModalPassword] = useState(false);
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [errors, setErrors] = useState({});

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "password",
					value: changePassword.password,
				},
				{
					required: true,
					password: true,
					min: 8,
				}
			),
		});
	};

	const handlePassword = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		const getUserData = async () => {
			if (
				userType === "company-super-admin" ||
				userType === "company-user" ||
				userType === "lab-super-admin" ||
				userType === "lab-user"
			) {
				const res = await getCurrentUser();
				if (res?.status == 200) {
					dispatch({ type: "CurrentUser", payload: res });
					setUserData(res.data);
					console.log(userData);
					// document.title = userData?.organization?.name;
				}
			}
		};
		if (isAuth) {
			getUserData();
		}
	}, []);

	const togglePassword = (e) => {
		setChangePassword({});
		setUserId(e);
		setModalPassword(!modalPassword);
	};

	const renderNav = () => {
		if (userType == "null" || userType == "") {
			return (
				<div className="bg-grey text-white">
					<div className="container-fluid">
						<div className="d-flex route-block">
							<div>
								<NavLink to="/admin" exact={true} activeClassName="is-active">
									{Labs.dashboard}
								</NavLink>
							</div>
							<div>
								<NavLink
									to="/admin/list-company"
									exact={true}
									activeClassName="is-active"
								>
									{Labs.companies}
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			);
		} else if (
			userType === "company-super-admin" ||
			userType === "company-user"
		) {
			return (
				<div className="bg-grey text-white">
					<div className="container-fluid">
						<div className="d-flex route-block">
							<div>
								<NavLink to="/companies" activeClassName="activeClass" exact>
									{Labs.dashboard}
								</NavLink>
							</div>
							{/* <div>
								<NavLink
									to="/companies/list-company"
									exact={true}
									activeClassName="is-active"
								>
									{Labs.companies}
								</NavLink>
							</div> */}
							<ShowForPermission permission="company:list-insurance-policies">
								<div>
									<NavLink
										to="/companies/travelers"
										activeClassName="activeClass"
									>
										{Labs.travelers}
									</NavLink>
								</div>
							</ShowForPermission>
							<ShowForPermission permission="company:list-labs">
								<div>
									<NavLink
										to="/companies/lab-management"
										activeClassName="activeClass"
									>
										{teamManagement.labManagement}
									</NavLink>
								</div>
							</ShowForPermission>
							<ShowForPermission
								permission={["company:list-users", "company:list-groups"]}
							>
								<div>
									<NavLink exact={true} to="/companies/team-management">
										{teamManagement.teamManagement}
									</NavLink>
								</div>
							</ShowForPermission>
						</div>
					</div>
				</div>
			);
		} else if (userType === "lab-super-admin" || userType === "lab-user") {
			return (
				<div className="bg-grey text-white">
					<div className="container-fluid">
						<div className="d-flex route-block">
							<div>
								<NavLink exact={true} activeClassName="is-active" to="/lab/">
									{Labs.dashboard}
								</NavLink>
							</div>
							<ShowForPermission permission="lab:list-insurance-policies">
								<div>
									<NavLink
										exact={true}
										activeClassName="is-active"
										to="/lab/labs-list"
									>
										{Labs.travelers}
									</NavLink>
								</div>
							</ShowForPermission>
							<ShowForPermission
								permission={["lab:list-users", "lab:list-groups"]}
							>
								<div>
									<NavLink
										exact={true}
										to="/lab/team-management"
										activeClassName="is-active"
									>
										{teamManagement.teamManagement}
									</NavLink>
								</div>
							</ShowForPermission>
						</div>
					</div>
				</div>
			);
		} else if (userType === "branch-super-admin") {
			return (
				<div className="bg-grey text-white">
					<div className="container-fluid">
						<div className="d-flex route-block">
							<div>
								<NavLink exact to="/branches" activeClassName="activeClass">
									{Labs.travelers}
								</NavLink>
							</div>
							<div>
								<NavLink
									to="/branches/team-management"
									activeClassName="activeClass"
								>
									{teamManagement.teamManagement}
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			);
		}
	};

	return (
		<header>
			<div className="py-2 bg-blue">
				{isAuth ? (
					<div className="container-fluid">
						<div className="row align-items-center">
							<div className="col-3">
								<p className="mb-0 text-white mx-3">
									{Labs.welcome}: {userData.name}
								</p>
							</div>
						</div>
					</div>
				) : null}
			</div>
			<nav className="navbar navbar-expand-lg shadow-sm navbar-light">
				<div className="container-fluid">
					<Link className="navbar-brand mx-0" to="/">
						<img className="w-10" src={logoImg} />
						<span className="font-weight-bold px-2">
							{Labs.laboratorySystem}
						</span>
					</Link>
					{/* <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>  */}
					<div className="settingLink">
						<div className="dropdowns d-flex align-items-center">
							<LanguageSwitcher className="bg-white" />
							{isAuth ? <UserSettings changePassword={changePassword} /> : ""}
						</div>
					</div>
				</div>
			</nav>
			{renderNav()}

			<Modal isOpen={modalPassword} toggle={togglePassword}>
				<ModalHeader toggle={togglePassword}>Modal title</ModalHeader>
				<ModalBody>
					<div className="row">
						<div className="col-md-12">
							<PasswordField
								type="text"
								label={teamManagement.password}
								placeholder={teamManagement.password}
								name="password"
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(e.target, {
											required: true,
											password: true,
											min: 8,
										}),
									});
									setChangePassword({
										...changePassword,
										password: e.target.value,
									});
								}}
								min={8}
								color={
									errors?.password?.required ||
									errors?.password?.password ||
									errors?.password?.min
										? "danger"
										: ""
								}
								errors={errors?.password}
								value={changePassword.password}
							/>
						</div>
						<div className="col-md-12">
							<PasswordField
								type="text"
								label={teamManagement.password_confirmation}
								placeholder={teamManagement.password_confirmation}
								name="password_confirmation"
								onChange={(e) => {
									setChangePassword({
										...changePassword,
										password_confirmation: e.target.value,
									});
								}}
								color={errors?.password_confirmation?.required ? "danger" : ""}
								errors={errors?.password_confirmation}
								value={changePassword.password_confirmation}
							/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={handlePassword}>
						submit
					</Button>{" "}
					<Button color="secondary" onClick={togglePassword}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</header>
	);
}

export default Header;
