// import sitting from "../assets/images/setting.png";
// import { fetchImages } from "services/auth";
// import { fetchUserProfile } from "services/profile";
import { useLabsDispatch } from "context/global";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import Locale from "translations";

export default function UserSettings({ changePassword }) {
	const { Labs } = Locale;
	const history = useHistory();
	const dispatch = useLabsDispatch();

	const [isAdmin] = useState(true);

	// useEffect(() => {
	// 	if (isAuth) {
	// 		async function fetchData() {
	// 			const user = await fetchUserProfile();
	// 			if (user.avatar) {
	// 				const fetchImage = await fetchImages(user.avatar);
	// 				if (fetchImage.status === 200) {
	// 					setAvatar(fetchImage);
	// 				}
	// 			}
	// 		}
	// 		fetchData();
	// 	}
	// }, [isAuth]);

	return (
		<UncontrolledDropdown className="setting-dropdown">
			<DropdownToggle className="btn-light p-0">
				<div className="text-gray">
					<i className="fas fa-cog fa-lg mx-1"></i>
					<span className="text-headline">{Labs.setting}</span>
				</div>
			</DropdownToggle>

			<DropdownMenu right>
				{/*  satart lab data */}
				{/*
				<Link to="/user-profile">
					<DropdownItem className="text-gray">
						{Labs.labData}
						<i className="fas fa-user-circle px-2 fa-fw"></i>
					</DropdownItem>
				</Link>
				*/}
				{/*  end lab data */}

				{isAdmin && (
					<Link onClick={() => changePassword}>
						<DropdownItem className="text-gray">
							{Labs.changePassword}
							<i className="fas fa-unlock-alt px-2 fa-fw"></i>
						</DropdownItem>
					</Link>
				)}
				<DropdownItem
					className="text-gray"
					onClick={() => {
						dispatch({ type: "logout" });
						history.push("/auth/login");
					}}
				>
					{Labs.logout}
					<i className="fas fa-sign-out-alt px-2 fa-fw"></i>
				</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}
