import TextField from "components/shared/TextField";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Locale from "translations";
export default function Add({ modal, toggle }) {
	const { teamManagement, users } = Locale;

	return (
		<>
			<Modal className={`add-model`} isOpen={modal} toggle={toggle}>
				<div className="col-md-12 box-title-model">
					<p className="text-center title-model">{teamManagement.addUser}</p>
				</div>

				<ModalBody>
					<div className="col-md-12">
						<div className="col-md-12">
							<div className="row">
								<TextField
									type="text"
									label={users.userName}
									placeholder={users.userNamePlaceholder}
									// value={addInvitationState.companyName}
								/>
							</div>
							<div className="row">
								<TextField
									type="text"
									label={users.email}
									placeholder={users.emailPlaceholder}
									// value={addInvitationState.companyName}
								/>
							</div>
							<div className="row">
								<TextField
									type="text"
									label={users.lab}
									placeholder={users.labName}
									// value={addInvitationState.companyName}
								/>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-cancel" onClick={toggle}>
						{teamManagement.cancel}
					</Button>
					<Button className="btn btn-add" onClick={toggle}>
						{teamManagement.add}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
