import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React from "react";
import Locale from "translations";
export default function Filter() {
	const { branches } = Locale;

	return (
		<>
			<div className="filter-container">
				<div className="filter-box">
					<TextField
						type="text"
						placeholder={branches.branchName}
						hasLabel={false}
						value={""}
						onChange={(e) => {}}
					/>
				</div>

				<div className="filter-box">
					<SelectField
						hasLabel={false}
						value={""}
						placeholder={branches.status}
						name="country"
						options={["countries"]}
						onChange={(e) => {}}
					/>
				</div>
			</div>
		</>
	);
}
