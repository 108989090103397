import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React from "react";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import Add from "./Add";
export default function Filter({ modal, toggle }) {
	const { teamManagement, users } = Locale;
	const history = useHistory();
	return (
		<>
			<div className="filter-container">
				<div className="filter-box">
					<TextField
						type="text"
						placeholder={users.userName}
						hasLabel={false}
						value={""}
						onChange={(e) => {}}
					/>
				</div>

				<div className="filter-box">
					<SelectField
						hasLabel={false}
						value={""}
						placeholder={users.status}
						name="country"
						options={["countries"]}
						onChange={(e) => {}}
					/>
				</div>

				<div className="btn-boxs">
					<button onClick={toggle}>{teamManagement.addUser}</button>
				</div>
			</div>

			<Add modal={modal} toggle={toggle} />
		</>
	);
}
