import React from "react";
import Locale from "translations";

export default function Counts() {
	const { teamManagement } = Locale;
	return (
		<>
			<div className="itemBoxCircle">
				<div className="boxCircle d-flex align-items-center shadow-sm">
					<span className="counter">6</span>
					<span className="circle px-2">
						<i className="fas fa-circle"></i>
					</span>
					<p>{teamManagement.userCount}</p>
				</div>
			</div>
			<div className="itemBoxCircle">
				<div className="boxCircle d-flex align-items-center shadow-sm">
					<span className="counter green">6</span>
					<span className="circle green px-2">
						<i className="fas fa-circle"></i>
					</span>
					<p>{teamManagement.activationUsers}</p>
				</div>
			</div>
			<div className="itemBoxCircle">
				<div className="boxCircle d-flex align-items-center shadow-sm">
					<span className="counter red">0</span>
					<span className="circle red px-2">
						<i className="fas fa-circle"></i>
					</span>
					<p>{teamManagement.inactivateUsers}</p>
				</div>
			</div>
		</>
	);
}
