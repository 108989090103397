import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;
const [protocol, domain] = baseURL.split("//");

const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;

const tenantURL = `https://${subdomain}.${domain}/api/tenant/companies`;

export const listTravellers = async (page = 1) => {
	// console.log("tenantURL", tenantURL);
	return await axios
		.get(tenantURL + "/insurance-policies?page=" + page)
		.then((res) => res)
		.catch((err) => err.response);
};
