import React from "react";
import Locale from "translations";
import List from "./List";

export default function index() {
	const { Labs } = Locale;
	return (
		<>
			<section className="bg-gray py-3 medical-section">
				<div className="container-fluid">
					{/* <Filter /> */}
					<List />
				</div>
			</section>
		</>
	);
}
