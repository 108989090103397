import React from "react";

export default function CheckBox(props) {
	return (
		<>
			<input
				type="Checkbox"
				name={props.name}
				value={props.value}
				checked={props.checked}
				onChange={props.onChange}
				id={props.id}
			/>

			<label
				className="form-check-label pointer text-caption mx-2"
				htmlFor={props.id}
			>
				{props.text}
			</label>
		</>
	);
}
