// import * as React from "react";

import labsReducer from "context/reducers/labsReducer";
import React, { createContext, useContext, useReducer } from "react";
const LabsContext = createContext(undefined);
const LabsDispatchContext = createContext(undefined);
const currentLocale = localStorage.getItem("currentLocale");

const initialState = {
	locale: currentLocale ?? "en",
	isAuth: localStorage.getItem("isAuth") ?? false,
	token: localStorage.getItem("token") ?? null,
	userType: localStorage.getItem("userType") ?? null,
	permissions: localStorage.getItem("permissions")
		? JSON.parse(localStorage.getItem("permissions"))
		: [],
};

function useLabsState() {
	const context = useContext(LabsContext);
	if (context === undefined) {
		throw new Error("useLabsState must be used within a LabsProvider");
	}
	return context;
}

function useLabsDispatch() {
	const context = useContext(LabsDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useMarketplaceDispatch must be used within a MarketPlaceProvider"
		);
	}
	return context;
}

function LabsProvider({ children }) {
	const [state, dispatch] = useReducer(labsReducer, initialState);
	return (
		<LabsContext.Provider value={state}>
			<LabsDispatchContext.Provider value={dispatch}>
				{children}
			</LabsDispatchContext.Provider>
		</LabsContext.Provider>
	);
}

export { LabsProvider, useLabsState, useLabsDispatch };
