import React from "react";
import Group from "./Group";

export default function GroupTable({ users }) {
	return (
		<>
			<div className="content-team">
				<div className="collapse show">
					<div className="card card-body p-0 mt-3">
						<div className="table-responsive "></div>
						<table className="table table-striped mb-0">
							<thead>
								<tr className="bg-grey text-white">
									<th>الاسم</th>

									<th>البريد الالكتروني</th>
									<th>الحالة</th>
								</tr>
							</thead>
							<tbody>
								{users && users.length > 0
									? users.map((res) => <Group user={res} />)
									: ""}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
