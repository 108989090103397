import { useLabsState } from "context/global";
import React from "react";

export default function ShowForPermission(props) {
	let LabsState = useLabsState();
	console.log("props.permission", props.permission);

	if (LabsState.permissions && LabsState.permissions.length > 0) {
		if (Array.isArray(props.permission)) {
			return LabsState.permissions.includes(...props.permission) ? (
				<div>{props.children}</div>
			) : null;
		} else {
			return LabsState.permissions.includes(props.permission) ? (
				<div>{props.children}</div>
			) : null;
		}
	} else if (LabsState.userType.includes("super-admin")) {
		return <div>{props.children}</div>;
	}
}
