import React, { useState } from "react";
import Locale from "translations";
import Filter from "./Filter";
import List from "./List";

export default function Travelers() {
	const { Labs } = Locale;
	const [filter, setfilter] = useState({
		lab_name: "",
		national_id: "",
		passport_number: "",
		status: null,
		gender: null,
	});

	return (
		<>
			<section className="main-color medical-section">
				<div className="container-fluid">
					<Filter filter={filter} setfilter={setfilter} />
					<List filter={filter} />
				</div>
			</section>
		</>
	);
}
