import React, { useState } from "react";
import Locale from "translations";
import ChangePassword from "./ChangePassword";
export default function User({ toggle }) {
	const { users } = Locale;

	//Change Password User
	const [modalPassword, setModalPassword] = useState(false);
	const togglePassword = () => setModalPassword(!modalPassword);

	return (
		<>
			<tr>
				<td>Lab Name</td>
				<td>email@email.com</td>
				<td>Lab Name</td>
				<td>
					<i className="far fa-check-circle fa-2x text-success fa-fw"></i>
				</td>
				<td>
					<button
						className="font14 btn-default border-0 font-weight-bold text-gray bg-transparent"
						type="button"
						onClick={toggle}
					>
						<i className="far fa-edit fa-fw"></i> {users.edit}
					</button>
				</td>
				<td>
					<button
						type="button"
						className="font14 btn-default border-0 font-weight-bold bg-transparent text-danger"
					>
						<i className="fas fa-stop fa-fw"></i> {users.stop}
					</button>

					{/* <button
						type="button"
						className="font14 btn-default border-0 font-weight-bold bg-transparent text-success"
					>
						<i className="fas fa-play"></i> تفعيل
					</button> */}
				</td>
				<td>
					<button
						type="button"
						className="font14 btn-default border-0 font-weight-bold text-gray bg-transparent"
						onClick={togglePassword}
					>
						<i className="fas fa-lock fa-fw "></i> {users.changePassword}
					</button>
				</td>

				<td>
					<button type="button" className="btn btn-danger">
						{users.remove}
					</button>
				</td>
			</tr>
			<ChangePassword
				modalPassword={modalPassword}
				togglePassword={togglePassword}
			/>
		</>
	);
}
