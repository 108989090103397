import React from "react";
import TeamManagement from "./labs";

export default function labManagement() {
	return (
		<div>
			<TeamManagement />
		</div>
	);
}
