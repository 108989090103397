import ShowForPermission from "helpers/showForPermission";
import React, { useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";
import Locale from "translations";
import GroupTable from "./GroupTable";

export default function ListGroups({ group, toggleEditGroup }) {
	const { teamManagement } = Locale;
	const [isOpen, setIsOpen] = useState(false);
	const toggleButton = () => setIsOpen(!isOpen);

	return (
		<>
			<div className="col-md-12 mb-2">
				<div className="headTeam rounded-right list-groups">
					<div className="row ">
						<div className="col">
							<button
								className="button-view text-fix btn-block  p-2  "
								onClick={() => (group.users.length > 0 ? toggleButton() : "")}
								type="button"
								style={{ fontSize: 15 }}
							>
								<span className=" rounded-right p-2 pr-4 ">
									{group.users.length > 0 ? (
										!isOpen ? (
											<i class="fas fa-chevron-down"></i>
										) : (
											<i class="fas fa-chevron-up"></i>
										)
									) : (
										<i class="fas fa-minus"></i>
									)}
								</span>
								{group.name}
							</button>
						</div>
						<div className="col">
							{/* <p className="p-2 text-center m-0  ">البنك المركزى المصري</p> */}
						</div>
						<div className="col">
							<p className="p-2 text-center m-0 " style={{ fontSize: 15 }}>
								{teamManagement.userCount} :
								<span className="rounded-circle num ml-1 mr-1 ">
									{group.users.length}
								</span>
							</p>
						</div>
						<div className="col">
							<ul className="p-2 mr-2 float-right">
								<li className="d-block m-1 ">
									<ShowForPermission permission="company:edit-groups">
										<button className="text-gray btn btn-link ">
											<a
												href={() => false}
												onClick={() => toggleEditGroup(group.id)}
												className="pointer text-gray btn-link"
												style={{ fontSize: 15 }}
											>
												<i className="far fa-edit"></i>
												{teamManagement.edit}
											</a>
										</button>
									</ShowForPermission>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<Collapse isOpen={isOpen}>
					<Card>
						<CardBody>
							<GroupTable users={group.users} />
						</CardBody>
					</Card>
				</Collapse>
			</div>
		</>
	);
}
