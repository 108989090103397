import React from "react";
import { useHistory } from "react-router-dom";
export default function Branch() {
	const history = useHistory();
	return (
		<>
			<tr>
				<td>Branch Name</td>
				<td>Cairo</td>
				<td>email@email.com</td>
				<td>023713734</td>
				<td>
					<i className="far fa-check-circle fa-2x text-success fa-fw"></i>
				</td>
			</tr>
		</>
	);
}
