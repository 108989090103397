import axios from "axios";
import { cleanEmpty } from "./general";
const baseURL = process.env.REACT_APP_API_URL;
const [protocol, domain] = baseURL.split("//");

const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;

const tenantURL = `https://${subdomain}.${domain}/api/tenant`;

const URL = `https://${subdomain}.${domain}/api`;

export const listTravellers = async () => {
	// console.log("tenantURL", tenantURL);
	return await axios
		.get(tenantURL + "/travellers/list")
		.then((res) => res)
		.catch((err) => err.response);
};

export const createLab = async (data) => {
	return await axios
		.post(`${tenantURL}/companies/labs`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editLab = async (data, id) => {
	return await axios
		.put(`${tenantURL}/companies/labs/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getLab = async (id) => {
	return await axios
		.get(`${tenantURL}/companies/labs/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listLabs = async (page = 1) => {
	return await axios
		.get(`${tenantURL}/companies/labs?page=${page}`)
		.then((res) => res)
		.catch((err) => err.response);
};

/**** Labs *****/
export const travelerLabs = async (page = 1) => {
	// console.log("tenantURL", tenantURL);
	return await axios
		.get(`${tenantURL}/labs/insurance-policies?page=` + page)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeStatusTraveller = async (id) => {
	return await axios
		.post(`${tenantURL}/labs/insurance-policies/${id}/approve`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getCurrentUser = async (id) => {
	return await axios
		.get(`${tenantURL}/auth/me`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const branchesList = async (params) => {
	return await axios
		.get(`${tenantURL}/labs/branches`, { params })
		.then((res) => res)
		.catch((err) => err.response);
};

export const createBranch = async (data) => {
	return await axios
		.post(`${tenantURL}/labs/branches`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editBranch = async (data, id) => {
	return await axios
		.put(`${tenantURL}/labs/branches/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getBranch = async (id) => {
	return await axios
		.get(`${tenantURL}/labs/branches/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const activateBranch = async (id, status) => {
	return await axios
		.patch(`${tenantURL}/labs/branches/${id}/${status}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listUsersForBranch = async (id) => {
	return await axios
		.get(`${tenantURL}/labs/branches/${id}/users`)
		.then((res) => res)
		.catch((err) => err.response);
};

// export const deleteBranch = async (id) => {
// 	return await axios
// 		.delete(`${tenantURL}/labs/branches/${id}`)
// 		.then((res) => res)
// 		.catch((err) => err.response);
// };

export const listUsers = async (page = 1) => {
	return await axios
		.get(`${tenantURL}/labs/users?page=${page}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeStatusLabApi = async (id, status) => {
	return await axios
		.patch(`${tenantURL}/companies/labs/${id}/${status}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changePassWordLabApi = async (id, status) => {
	return await axios
		.put(`${tenantURL}/companies/labs/${id}/change-password`, status)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editUser = async (data, id) => {
	return await axios
		.get(`${tenantURL}/labs/users${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const filterLabs = async (filter) => {
	return await axios
		.get(`${tenantURL}/companies/labs`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const filtetTravelers = async (filter) => {
	return await axios
		.get(`${tenantURL}/companies/insurance-policies`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
