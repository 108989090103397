import CheckBox from "components/shared/Chekbox";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import ShowForPermission from "helpers/showForPermission";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { fetchCountries } from "services/lookups";
import {
	addGroupAPI,
	addUserAPI,
	listGroupsDp,
	listPermissions,
} from "services/teamManagementCompany";
import Locale from "translations";
export default function Filter({ activePage, getNewData }) {
	const { teamManagement } = Locale;
	const [modal, setModal] = useState(false);
	let currentLocale = localStorage.getItem("currentLocale") ?? "en";

	const [user, setUser] = useState({});
	const [group, setGroup] = useState({});
	const toggle = () => setModal(!modal);
	const [errors, setErrors] = useState({});
	const [Permissions, setPermissions] = useState({ selected: [] });
	const [groupsDp, setgroupsDp] = useState([]);
	const [selected, setSelected] = useState([]);
	const [isErrorLoadedGroup, setIsErrorLoadedGroup] = useState(false);
	const [isErrorLoadedUser, setIsErrorLoadedUser] = useState(false);

	useEffect(() => {
		async function fetchData() {
			const usersData = await listPermissions();
			if (usersData.status === 200) {
				setPermissions({ ...Permissions, ...usersData.data });
			}

			const GroupsDp = await listGroupsDp();
			if (GroupsDp.status === 200) {
				const format = GroupsDp.data?.groups?.map((e) => ({
					value: e.id,
					label: e.name,
				}));
				setgroupsDp(format);
			}
		}
		fetchData();
	}, []);
	const [countries, setCountries] = useState([]);

	useEffect(() => {
		async function fetchData() {
			const GroupsDp = await listGroupsDp();
			if (GroupsDp.status === 200) {
				const format = GroupsDp.data?.groups?.map((e) => ({
					value: e.id,
					label: e.name,
				}));
				setgroupsDp(format);
			}
		}
		fetchData();
	}, [modal]);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate({ name: "name", value: user.name }, { required: true }),
			...validate({ name: "email", value: user.email }, { required: true }),
			...validate(
				{ name: "phone_number", value: user.phone_number },
				{ required: true }
			),
		});
	};

	const checkFormGroupErrors = () => {
		setErrors({
			...errors,
			...validate({ name: "name", value: group.name }, { required: true }),
		});
	};

	const AllPermissions = Object.keys(Permissions).map((key) => {
		if (key != "selected") {
			return (
				<div className="col-md-3" key={`page-${key}`}>
					<div className="card-group">
						<div className="box-title">
							{/* <CheckBox /> */}
							<h3>{key}</h3>
						</div>

						{Permissions[key].map((Permission, index) => {
							return (
								<div className="box-row" key={key + index}>
									{" "}
									<CheckBox
										key={key + index}
										onChange={(e) => handleCheck(e)}
										value={Permission.id}
										text={Permission.display_name[currentLocale]}
										checked={Permissions?.selected?.includes(Permission.id)}
									/>{" "}
								</div>
							);
						})}
					</div>
				</div>
			);
		}
	});

	const handleCheck = (e) => {
		let check = e.target.checked;
		let select = [...Permissions.selected];
		console.log(e.target.checked, e.target.value);
		if (check) {
			select.push(+e.target.value);
		} else {
			let ind = select.indexOf(+e.target.value);
			select.splice(ind, 1);
		}
		setSelected(select);
		setPermissions({ ...Permissions, selected: select });
	};

	const addGroup = () => {
		checkFormGroupErrors();
		if (!isErrorLoadedGroup) {
			setIsErrorLoadedGroup(true);
		} else {
			setIsErrorLoadedGroup(false);
		}
	};

	const adduser = () => {
		checkFormErrors();
		if (!isErrorLoadedUser) {
			setIsErrorLoadedUser(true);
		} else {
			setIsErrorLoadedUser(false);
		}
	};

	useEffect(() => {
		const addNewUser = async () => {
			if (isFormValid(errors)) {
				const res = await addUserAPI({
					...user,
					group_id: user.group_id.value,
					country_id: user.country.value,
				});
				setUser({});
				getNewData();
				toggle();
				if (res.status == 201) {
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		};

		addNewUser();
	}, [isErrorLoadedUser]);

	useEffect(() => {
		const addNewGroup = async () => {
			if (isFormValid(errors)) {
				const res = await addGroupAPI({
					name: group.name,
					permissions: selected,
				});
				if (res.status == 201) {
					toggle();
					setGroup({});
					getNewData();
					setSelected([]);
					setPermissions({ ...Permissions, selected: [] });
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		};

		addNewGroup();
	}, [isErrorLoadedGroup]);
	const locale = localStorage.getItem("currentLocale") || "en";

	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCountries(format);
		}
		fetchLookups();
	}, []);

	return (
		<>
			<div className="filter-container overflow-auto">
				<div className="filter-box">
					{/* <TextField
						type="text"
						placeholder={teamManagement.search}
						hasLabel={false}
						value={""}
						onChange={(e) => {}}
					/> */}
				</div>

				{activePage === 1 ? (
					<div className="filter-box">
						{/* <SelectField
							hasLabel={false}
							value={""}
							placeholder={teamManagement.status}
							name="country"
							options={["countries"]}
							onChange={(e) => {}}
						/> */}
					</div>
				) : (
					""
				)}

				<div className="btn-boxs">
					{activePage === 1 ? (
						<ShowForPermission permission="company:create-user">
							<button onClick={toggle}>{teamManagement.addUser}</button>
						</ShowForPermission>
					) : (
						<ShowForPermission permission="company:create-branch">
							<button onClick={toggle}>{teamManagement.addGroup}</button>
						</ShowForPermission>
					)}
				</div>
			</div>
			<Modal
				className={`add-model ${activePage === 2 ? "w-55" : ""} `}
				isOpen={modal}
				toggle={toggle}
			>
				<div className="col-md-12 box-title-model">
					<p className="text-center title-model">
						{activePage === 2
							? teamManagement.addGroup
							: teamManagement.addUser}
					</p>
				</div>

				<ModalBody>
					{activePage === 2 ? (
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-5">
									<TextField
										type="text"
										label={teamManagement.groupName}
										placeholder={teamManagement.groupName}
										name="name"
										onChange={(e) => {
											setGroup({
												...group,
												name: e.target.value,
											});
										}}
										color={errors?.name?.required ? "danger" : ""}
										errors={errors?.name}
										value={group.name}
									/>
								</div>
							</div>
							<div className="row">{AllPermissions}</div>
						</div>
					) : (
						<div className="col-md-12">
							<div className="col-md-12">
								<div className="row">
									<TextField
										type="text"
										label={teamManagement.name}
										placeholder={teamManagement.name}
										name="name"
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											setUser({
												...user,
												name: e.target.value,
											});
										}}
										color={errors?.name?.required ? "danger" : ""}
										errors={errors?.name}
										value={user.name}
									/>
								</div>
								<div className="row">
									<TextField
										type="text"
										label={teamManagement.email}
										placeholder={teamManagement.email}
										name="email"
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													email: true,
												}),
											});
											setUser({
												...user,
												email: e.target.value,
											});
										}}
										color={
											errors?.email?.required || errors?.email?.email
												? "danger"
												: ""
										}
										errors={errors?.email}
										value={user.email}
									/>
								</div>
								<div className="row">
									<SelectField
										type="text"
										name="country"
										label={teamManagement.country}
										placeholder={teamManagement.country}
										errors={errors.country}
										color={errors.country?.required ? "danger" : ""}
										options={countries}
										onChange={(e) => {
											setUser({
												...user,
												country: e,
											});
											setErrors({
												...errors,
												...validate(
													{ name: "country", value: e },
													{
														required: true,
													}
												),
											});
										}}
										value={user.country}
									/>
								</div>
								<div className="row">
									<TextField
										type="phone_number"
										label={teamManagement.phoneNumber}
										placeholder={teamManagement.phoneNumber}
										name="phone_number"
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											setUser({
												...user,
												phone_number: e.target.value,
											});
										}}
										color={errors?.phone_number?.required ? "danger" : ""}
										errors={errors?.phone_number}
										value={user.phone_number}
									/>
								</div>
								<div className="row">
									<SelectField
										type="text"
										name="country"
										label={teamManagement.groups}
										placeholder={teamManagement.groups}
										errors={errors.country}
										color={errors.country?.required ? "danger" : ""}
										options={groupsDp}
										onChange={(e) => {
											setUser({
												...user,
												group_id: e,
											});
										}}
										value={user.group_id}
									/>
								</div>
							</div>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					{activePage === 1 ? (
						<>
							<Button className="btn btn-cancel" onClick={toggle}>
								{teamManagement.cancel}
							</Button>
							<Button className="btn btn-add" onClick={adduser}>
								{teamManagement.add}
							</Button>
						</>
					) : (
						<>
							<Button className="btn btn-cancel" onClick={toggle}>
								{teamManagement.cancel}
							</Button>
							<Button className="btn btn-add" onClick={addGroup}>
								{teamManagement.add}
							</Button>
						</>
					)}
				</ModalFooter>
			</Modal>
		</>
	);
}
